import './Footer.css';

function Footer() {
    return (
        <div className="footer">
            <p>Brokerage:<br /> eXp Realty</p>
            <p>Email:<br /> mallorylaynehomes@gmail.com</p>
        </div>
    );
}

export default Footer;